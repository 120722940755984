import { FC } from 'react';

const Interests: FC = ({ children }) => {
  return (
    <div className="my-8">
      <h3 className="mb-3 font-bold text-2xl">Things I&#39;m interested in</h3>
      <ul className="grid grid-cols-3 gap-3 text-light">{children}</ul>
    </div>
  );
};

export default Interests;
