import { FC } from 'react';

import { ReactComponent as CheckMarkIcon } from '../../assets/icons/check-mark.svg';

interface InterestProps {
  value: string;
}

const Interest: FC<InterestProps> = ({ value }) => {
  return (
    <li className="p-1 text-base font-normal flex items-center">
      <CheckMarkIcon />
      <span className="ml-2">{value}</span>
    </li>
  );
};

export default Interest;
