import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faTwitter, faFacebook, faGithub } from '@fortawesome/free-brands-svg-icons';

import {
  ProfilePicture,
  ShareProfile,
  Interest,
  Interests,
  Faq,
  TimeSlots,
  Slot,
  Header,
  DatePicker,
} from '../../components';
import { STATIC_ROUTES } from '../../contants';

const DUMMY_SLOT_DATA = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }];

const ProfilePage = () => {
  const history = useHistory();

  return (
    <div>
      <Header />
      <div className="container mx-auto pb-6">
        <div className="averta mt-5 grid grid-cols-3 gap-8">
          <main className="p-8 col-span-2 bg-white rounded-lg">
            <div className="flex mb-8">
              <ProfilePicture
                className="w-80 h-52 mr-10"
                source="https://images.unsplash.com/photo-1583147610149-78ac5cb5a303?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              />
              <div className="text-dark flex flex-col">
                <div className="flex-1">
                  <h3 className="mb-2 text-4xl font-bold">Yonatan Bendaha</h3>
                  <span>
                    <FontAwesomeIcon icon={faStar} color="#3509FA" />
                    <span className="mx-2 font-semibold">4.98</span>
                    <span className="font-light text-gray-500">(800 reviews)</span>
                  </span>
                  <p className="mt-4">
                    Backend developer with over 18 years experience. Ex Google, Facebook. Stanford University Alum.
                  </p>
                </div>
                <div className="flex">
                  <span className="flex flex-1 items-center">
                    <span className="mr-2 text-xs text-light">FIND ME</span>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className="mr-2 hover:text-primary" icon={faLinkedin} />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className="mr-2 hover:text-primary" icon={faTwitter} />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className="mr-2 hover:text-primary" icon={faFacebook} />
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon className="mr-2 hover:text-primary" icon={faGithub} />
                    </a>
                  </span>

                  <ShareProfile />
                </div>
              </div>
            </div>

            <hr />
            <div className="my-8">
              <h3 className="mb-3 font-bold text-2xl">About me</h3>
              <p className="text-base font-normal text-light leading-6">
                Sr Web and Java developer by profession, your friend by nature. 1850+ sessions so far with average 5 *
                rating. Java and web developer for a decade, who loves to solve things and fix bugs.
                <br />
                <br />
                If I can&#39;t fix your issue, you&#39;ll get a refund. I do not start timer until you explain your
                issue (Or) if I am not sure about the issue
              </p>
            </div>
            <hr />

            <Interests>
              <Interest value="User experience" />
              <Interest value="Typography" />
              <Interest value="User research" />
              <Interest value="Design systems" />
              <Interest value="Portfolio review" />
              <Interest value="Usability testing" />
            </Interests>

            <hr />
            <div className="my-8">
              <h3 className="mb-3 font-bold text-2xl text-dark">Frequently Asked Questions</h3>
            </div>
            <hr />
            <Faq
              question="Where will the classes be?"
              answer="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit, cupiditate. Perspiciatis ipsam
            earum commodi saepe at magnam atque facere tempora quaerat rerum veritatis unde, sunt culpa id explicabo
            eligendi corrupti."
            />

            <Faq
              question="What will be included in a lesson?"
              answer="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit, cupiditate. Perspiciatis ipsam
            earum commodi saepe at magnam atque facere tempora quaerat rerum veritatis unde, sunt culpa id explicabo
            eligendi corrupti."
            />
            <Faq
              question="How long is each lesson?"
              answer="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit, cupiditate. Perspiciatis ipsam
            earum commodi saepe at magnam atque facere tempora quaerat rerum veritatis unde, sunt culpa id explicabo
            eligendi corrupti."
            />
            <Faq
              question="Can I book more than one lesson?"
              answer="Lorem ipsum dolor, sit amet consectetur adipisicing elit. Velit, cupiditate. Perspiciatis ipsam
            earum commodi saepe at magnam atque facere tempora quaerat rerum veritatis unde, sunt culpa id explicabo
            eligendi corrupti."
            />
          </main>
          <aside className="p-8 bg-white shadow-lg rounded-xl border border-shade profile__aside overflow-y-auto sticky top-24">
            <div className="flex items-center">
              <ProfilePicture
                className="w-14 h-14 mr-4"
                source="https://images.unsplash.com/photo-1583147610149-78ac5cb5a303?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              />
              <h6 className="mb-2 text-2xl font-bold">Book Yonata</h6>
            </div>
            <div className="my-4 text-4xl font-bold">$15-$20/hr</div>

            <DatePicker />

            <TimeSlots>
              {DUMMY_SLOT_DATA.map((el, i) => (
                <Slot
                  key={el.id}
                  isLast={typeof DUMMY_SLOT_DATA[i + 1] === 'undefined'}
                  onSelected={() => history.push(STATIC_ROUTES.CONFIRM_BOOKING)}
                />
              ))}
            </TimeSlots>
          </aside>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
