import { ButtonHTMLAttributes, FC } from 'react';

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({ children, className = '', ...rest }) => {
  return (
    <button
      className={`box-border px-4 py-2 h-10 flex items-center justify-center text-sm text-white bg-primary rounded-md ${className}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
