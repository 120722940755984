import React, { FC, useState } from 'react';
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';

import 'react-calendar/dist/Calendar.css';
import './DatePicker.css';

interface DatePickerProps {
  initialDate?: Date;
  minDate?: Date;
}

const DatePicker: FC<DatePickerProps> = ({ initialDate, minDate }) => {
  const [rawDate, setRawDate] = useState<Date>(initialDate ?? new Date());
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col w-full">
        <label className="mb-1 text-sm font-semibold">Date</label>
        <div className={`w-full flex items-center rounded-lg border border-gray-300 px-3 py-2`}>
          <FontAwesomeIcon icon={faCalendarDay} color="#626262" />
          <input
            className={`ml-2 text-dark bg-transparent outline-none w-full`}
            value={format(rawDate, 'MMM d, yyyy')}
            onClick={() => setIsOpen(true)}
            data-testid="calendar-input"
          />
        </div>
      </div>
      {isOpen && (
        <Calendar value={rawDate} onChange={(date) => setRawDate(date as Date)} minDate={minDate ?? new Date()} />
      )}
    </>
  );
};

export default DatePicker;

// NOTE: Calendar docs 👉🏾 https://github.com/wojtekmaj/react-calendar
