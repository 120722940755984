/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ReactComponent as BookingDone } from '../../assets/icons/booking-done.svg';
import { Button, Header } from '../../components';

const BookingSuccessful = () => {
  return (
    <div css={style} className="min-w-screen min-h-screen flex flex-col">
      <Header />
      <div className="flex-1 flex items-center justify-center">
        <div className="booking-success averta rounded-lg flex flex-col items-center justify-center bg-white">
          <div className="w-2/3 flex flex-col items-center text-center">
            <BookingDone />
            <h3 className="mt-8 mb-6 font-bold text-2xl text-dark">Booking successful</h3>
            <p className="mb-6 text-base font-normal text-light">
              Payment was successful and the booking has been made. Please check your email for further details.
            </p>
            <Button className="w-full">Go home</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const style = css`
  .booking-success {
    width: 600px;
    height: 530px;
  }
`;

export default BookingSuccessful;
