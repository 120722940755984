import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { STATIC_ROUTES } from './contants';
import { BookingSuccessful, ConfirmBooking, ProfilePage } from './pages';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/profile/:username" component={ProfilePage} />
        <Route exact path={STATIC_ROUTES.BOOKING_SUCCESS} component={BookingSuccessful} />
        <Route exact path={STATIC_ROUTES.CONFIRM_BOOKING} component={ConfirmBooking} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
