import { FC, HTMLAttributes } from 'react';

interface ProfilePictureProps extends HTMLAttributes<HTMLDivElement> {
  source: string;
}

const ProfilePicture: FC<ProfilePictureProps> = ({ className, source }) => {
  return (
    <div className={`rounded-lg overflow-hidden ${className}`}>
      <img className="w-full h-full object-cover" src={source} alt="Profile" />
    </div>
  );
};

export default ProfilePicture;
