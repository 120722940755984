import { FC } from 'react';
import { Button } from '..';

interface SlotProps {
  isLast: boolean;
  onSelected: () => void;
}

const Slot: FC<SlotProps> = ({ isLast, onSelected }) => {
  return (
    <li>
      <div className="w-full flex">
        <div className="w-1/2 flex flex-col">
          <span className="text-sm font-semibold">6:00 PM - 7:00 PM (WAT)</span>
          <span className="text-sm font-light">Jan 15, 2020</span>
        </div>
        <div className="w-1/2 flex items-center justify-end">
          <span className="mr-4 text-base text-info font-bold">$ 15.00</span>
          <Button onClick={onSelected}>Select</Button>
        </div>
      </div>
      {!isLast && <hr className="my-5" />}
    </li>
  );
};

export default Slot;
