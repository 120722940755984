import * as Yup from 'yup';

const RequiredString = (message: string) => Yup.string().trim().required(message);
const RequiredEmail = Yup.string().trim().email().required();

export const BookingSchema = Yup.object().shape({
  firstName: RequiredString('First name is required'),
  lastName: RequiredString('Last name is required'),
  email: RequiredEmail,
  phone: RequiredString('Phone number is required'), // TODO: Improve phone number validation. (Only nigerian numbers supported?)
});
