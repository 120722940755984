import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt, faTimes, faCopy } from '@fortawesome/free-solid-svg-icons';
import { faTwitter, faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Modal } from '../../components';

const ShareProfile = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className="focus:outline-none flex items-center"
        onClick={() => setIsOpen(true)}
        data-testid="toggle-share"
      >
        <FontAwesomeIcon className="mr-2" icon={faShareAlt} /> Share Profile
      </button>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex flex-col" data-testid="share-content">
          <div className="pb-8 flex items-center justify-between text-xl md:text-2xl font-bold">
            <h3>Share this profile</h3>
            <button className="py-1 px-2" onClick={() => setIsOpen(false)}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <ul className="text-xl">
            <li className="py-6 border-t border-b">
              <a href="#">
                <FontAwesomeIcon icon={faTwitter} />
                <span className="ml-3">Twitter</span>
              </a>
            </li>
            <li className="py-6 border-b">
              <a href="#">
                <FontAwesomeIcon icon={faFacebook} />
                <span className="ml-3">Facebook</span>
              </a>
            </li>
            <li className="py-6 border-b">
              <a href="#">
                <FontAwesomeIcon icon={faWhatsapp} />
                <span className="ml-3">WhatsApp</span>
              </a>
            </li>
            <li className="py-6">
              <a href="#">
                <FontAwesomeIcon icon={faCopy} />
                <span className="ml-3">Copy link</span>
              </a>
            </li>
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ShareProfile;
