import { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface FaqProps {
  question: string;
  answer: string;
}
const Faq: FC<FaqProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        className={`w-full ${isOpen ? 'mt-4' : 'my-4'} py-2 flex items-center justify-between cursor-pointer`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-bold text-base text-dark">{question}</h3>
        <FontAwesomeIcon className="mr-4" icon={isOpen ? faChevronUp : faChevronDown} data-testid="toggle-question" />
      </div>
      {isOpen && <div className="mb-6 text-base text-light">{answer}</div>}
      <hr />
    </>
  );
};

export default Faq;
