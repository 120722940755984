import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import { Button, Field, Header, ProfilePicture, BackButton } from '../../components';
import { BookingSchema } from '../../utils/validationSchemas';

const ConfirmBooking = () => {
  const history = useHistory();

  const onBookingSubmitted = (values: any) => {
    // TODO:
    console.log({ values });
  };

  return (
    <>
      <Header />
      <div className="container mx-auto py-6">
        <div className="flex items-center">
          <BackButton onClick={() => history.goBack()} />
          <span className="ml-4 averta font-bold text-2xl">Confirm booking with Yonatan</span>
        </div>
        <div className="averta mt-5 grid grid-cols-3 gap-8">
          <aside className="bg-white rounded-xl border border-shade profile__aside p-8">
            <div className="mb-4 h-28 flex items-center">
              <ProfilePicture
                className="w-32 h-28 mr-4"
                source="https://images.unsplash.com/photo-1583147610149-78ac5cb5a303?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
              />
              <div className="text-dark flex flex-col">
                <div className="flex-1">
                  <h3 className="mb-2 text-2xl font-bold">Yonatan Bendaha</h3>
                  <span className="text-base">
                    <FontAwesomeIcon icon={faStar} color="#3509FA" />
                    <span className="mx-2 font-semibold">4.98</span>
                    <span className="font-light text-gray-500">(800 reviews)</span>
                  </span>
                </div>
              </div>
            </div>

            <hr />

            <div className="my-4">
              <span className="mb-2 text-xl font-bold">Total price</span>
              <div className="flex items-center justify-between text-base font-normal">
                <span>$15.00 x 1 hour</span>
                <span>$15.00</span>
              </div>
            </div>

            <hr />

            <div className="mt-6">
              <span className="mb-2 text-xl font-bold">How to reschedule</span>
              <p className="text-gray-500">
                This booking can be rescheduled within 24 hours of purchase. This can be done by clicking on the
                rescheduling link in the email.
              </p>
            </div>
          </aside>
          <main className="p-8 col-span-2 bg-white rounded-lg">
            <h3 className="mb-4 text-4xl font-bold">Your info</h3>
            <Formik
              initialValues={{ firstName: '', lastName: '', email: '', phone: '' }}
              validationSchema={BookingSchema}
              onSubmit={onBookingSubmitted}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="mb-12 grid grid-cols-2 grid-rows-2 gap-4">
                    <div className="form-field">
                      <Field
                        type="text"
                        label="First name"
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        touched={touched.firstName}
                        error={errors.firstName}
                      />
                    </div>
                    <div className="form-field">
                      <Field
                        type="text"
                        label="Last name"
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        touched={touched.lastName}
                        error={errors.lastName}
                      />
                    </div>
                    <div className="form-field">
                      <Field
                        type="email"
                        label="Email address"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        touched={touched.email}
                        error={errors.email}
                      />
                    </div>
                    <div className="form-field">
                      <Field
                        type="phone"
                        label="Phone number"
                        value={values.phone}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                        touched={touched.phone}
                        error={errors.email}
                      />
                    </div>
                  </div>
                  <hr />
                  <Button className="mt-12 w-52" type="submit">
                    Pay $15
                  </Button>
                </form>
              )}
            </Formik>
          </main>
        </div>
      </div>
    </>
  );
};

export default ConfirmBooking;
