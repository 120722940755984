import { ReactComponent as Logo } from '../../assets/icons/logo.svg';

const Header = () => {
  return (
    <header className="py-6 flex items-center bg-transparent">
      <div className="container mx-auto">
        <Logo />
      </div>
    </header>
  );
};

export default Header;
