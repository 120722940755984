import { FC, InputHTMLAttributes } from 'react';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error-icon.svg';

interface FieldPropTypes extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (value: any) => void;
  value: any;
  icon?: JSX.Element;
  label?: string;
  error?: any;
  touched?: any;
}

const Field: FC<FieldPropTypes> = ({ label, value, onChange, icon, error, touched, ...rest }) => {
  const showError = error && touched;

  return (
    <div className="flex flex-col w-full averta text-dark">
      {label && <label className="mb-1 font-normal text-base">{label}</label>}
      <div
        className={`w-full flex items-center rounded border border-gray-300 px-3 py-2 ${
          showError ? 'bg-danger-light border-danger' : 'mb-2'
        }`}
      >
        {icon && icon}
        <input
          {...rest}
          className={`ml-2 text-dark bg-transparent outline-none w-full`}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
      {showError && (
        <span className="my-2 text-danger text-sm flex items-center">
          <ErrorIcon className="mr-2" />
          {error}
        </span>
      )}
    </div>
  );
};

export default Field;
